import styled from 'styled-components';
import {Modal} from "react-bootstrap";
const Bold = styled.div`
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
   text-align: center;
  margin-bottom: 25px;
   margin-top: 25px;
  color: #3A3A3A;
  .subContent {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #9C9C9C;
    margin-top: 5px;
  }
`;

const StyledModal = styled(Modal)`
  .modal-backdrop.in{
    opacity: .2;
  }
  .modal-dialog {
    transform: translate(-50%, -50%);
  }
  .delete-popup { 
    top: 30%;
    width: 400px;
    height: 90px;
    margin: 0 auto;
    .modal-header {
      border-bottom: 0px;
      background: linear-gradient( 
        180deg,#52A8ED 0%,#8AD3F7 100%);
      border-radius: 6px 6px 0 0;
    } 
  }
 @media (max-width: 500px){
   .modal-dialog {
    width: 80%;
    }
 }
`;

const SubmitContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .biometric-button {
    font-size: 16px;
  }
`;

const StyledModalV2 = styled(Modal)`
  .modal-backdrop.in{
    opacity: .2;
  }
  .modal-dialog {
    transform: translate(-50%, -50%);
  }
  .delete-popup {
    width: 485px; 
    top: 30%;
    margin: 0 auto;
    .modal-header {
      border-bottom: 0px;
      background: linear-gradient( 
        180deg,#52A8ED 0%,#8AD3F7 100%);
      border-radius: 6px 6px 0 0;
    } 
  }
 @media (max-width: 500px){
   .modal-dialog {
    width: 80%;
    }
 }
`;

const BoldV2 = styled.div`
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 25px;
    margin-top: 25px;
    color: #005C87;
    >span{
      font-family: 'Rubik-Medium';
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      margin-bottom: 25px;
      margin-top: 25px;
      color: #0D4270;
    }
  .subContent {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #005C8799;
    margin-top: 15px;
  }
`;

export { Bold, StyledModal, SubmitContainer, StyledModalV2, BoldV2 }