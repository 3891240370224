/* eslint-disable no-console,no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  StyledModal, CalendarHeader, CalendarWrapper, ConfirmVisitText, ButtonContainer, ButtonV2
} from '../PeopleHomeV2/ManageBuddies/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { deleteSocialInspiration, sendFriendRequest, getSuggestionsList, removeBuddy, acceptOrRejectFriendRequest} from '../../redux/actions';

class DeletePostPopup extends Component {
  constructor(props) {
    super(props);
  }

  deletePost = () => {
    const { deleteSocialInspiration, feed, onClose, updationList} = this.props;
    deleteSocialInspiration(feed.id, updationList);
    onClose();
  };

  addBuddy = (Id) => {
    const { sendFriendRequest, onClose, socialCallBack, getSuggestionsList} = this.props;
    sendFriendRequest(Id, false);
    getSuggestionsList();
    socialCallBack();
    onClose();
  }

  rejectBuddy = (Id) => {
    const { onClose, socialCallBack, removeBuddy, getSuggestionsList} = this.props;
    removeBuddy(Id);
    getSuggestionsList();
    socialCallBack();
    onClose();
  }

  acceptOrRejectRequest = (id, status) => {
    const { acceptOrRejectFriendRequest, onClose, socialCallBack } = this.props;
    onClose();
    acceptOrRejectFriendRequest({ request_id: id, status: status });
    socialCallBack();
  };

  render() {
    const { showModal, onClose, showpending, showAddBuddy, buddyDetails, showConfirm, requestId } = this.props;
    const userId= buddyDetails && buddyDetails.length && buddyDetails[0] && buddyDetails[0].uid;
    return (
      <StyledModal show={showModal ? true : false} width={"400px"}>
        <CalendarHeader style={{background:"#005c87"}}>
          <div>{this.props.t("Notification")}</div>
        </CalendarHeader>
        <CalendarWrapper padding="0 25px 25px 25px">
          {showpending?
            <ConfirmVisitText>
              {this.props.t("Are you sure you want to cancel")} <br/>{this.props.t("Buddy Request?")}
            </ConfirmVisitText>:
            showAddBuddy?
              <ConfirmVisitText>
                {this.props.t("Are you sure you want to send")} <br/>{this.props.t("Buddy Request?")}
              </ConfirmVisitText>:
              showConfirm?<ConfirmVisitText>
                {this.props.t("Are you sure to want to accept")} <br/>{this.props.t("the Buddy Request?")}
              </ConfirmVisitText>:
                <ConfirmVisitText>
                  {this.props.t("Are you sure you want to delete")} <br/>{this.props.t("this post?")}
                </ConfirmVisitText>
          }  
          <ButtonContainer>
            <ButtonV2
              background="#005c87"
              onClick={showConfirm?() => this.acceptOrRejectRequest(requestId,'Accepted'): showAddBuddy?() => this.addBuddy(userId):showpending?  () => this.rejectBuddy(userId):  () => this.deletePost()}
              width="100%"
              margin="0 0 10px 0"
              backgroundHover="#005C87"
              colorHover="white"
              color="white"
              border="1"
            >
              {showConfirm?this.props.t("Yes, Accept"): showpending?this.props.t("Yes, Cancel"):showAddBuddy?this.props.t("Yes, Send"): this.props.t("Yes, Delete it!")}
            </ButtonV2>
            <ButtonV2
              border="1"
              background="transparent"
              color="#005C8799"
              onClick={showConfirm?() => this.acceptOrRejectRequest(requestId, 'Rejected'): () => onClose()}
              width="100%"
              backgroundHover="#005C87"
              colorHover="white"
            >
              {showConfirm?this.props.t("Reject"): showpending?this.props.t("No"):showAddBuddy?this.props.t("No"):this.props.t("Cancel")}
            </ButtonV2>
          </ButtonContainer>
        </CalendarWrapper>
      </StyledModal>
    );
  }
}

DeletePostPopup.propTypes = {
  showModal: PropTypes.string,
  onClose: PropTypes.func,
  deleteSocialInspiration: PropTypes.func,
  feed: PropTypes.any,
  updationList: PropTypes.string,
  deleteUser: PropTypes.func,
  t: PropTypes.func,
  sendFriendRequest: PropTypes.func,
  getSuggestionsList: PropTypes.func,
  socialCallBack: PropTypes.func,
  removeBuddy: PropTypes.func,
  showpending: PropTypes.bool,
  showAddBuddy: PropTypes.bool,
  buddyDetails: PropTypes.array,
  showConfirm: PropTypes.bool,
  acceptOrRejectFriendRequest: PropTypes.func,
  requestId: PropTypes.bool
};

const mapStateToProps = (state) => ({
  sendRequest: state.social.sendRequest,
  friendRequests: state.social.friendRequests,
});

const mapDispatchToProps = (dispatch) => ({
  deleteSocialInspiration: (id, updationList) => dispatch(deleteSocialInspiration(id, updationList)),
  sendFriendRequest: (data, bool) => dispatch(sendFriendRequest(data, bool)),
  getSuggestionsList: () => dispatch(getSuggestionsList()),
  removeBuddy: (acceptData) => dispatch(removeBuddy(acceptData)),
  acceptOrRejectFriendRequest: (acceptData) => dispatch(acceptOrRejectFriendRequest(acceptData))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DeletePostPopup));