import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import {StyledBodyV2, SubmitContainerV2} from '../AddWellnessInitiative/styles';
import { UserButtonV2 } from '../EngagementSection/styles';
import { BoldV2, StyledModalV2 } from './styles';
import { StyledTitleV2 } from '../SocialFeeds/styles';
import { deleteSocialInspiration, UpdateAttendeesList} from '../../redux/actions';
import { withTranslation } from 'react-i18next';

class DeletePostPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    }

    this.cancelButton =  React.createRef();
  }

  deletePost = () => {
    const { articleId, deleteId, deleteQuizQuestion, deleteSocialInspiration, feed, onClose, updationList, questionPopup, deleteUser, uid, showModal, eventId, RemoveAttendees} = this.props;

    if(questionPopup) {
      deleteQuizQuestion(deleteId, articleId);
    } else if (uid) {
      if(showModal === 'removeAttendees') {
        let obj={};
        obj['event_id'] = eventId;
        obj['user_id'] = uid;
        obj['action_role'] = 'remove_event';
        RemoveAttendees(obj);
        onClose()
      }
      else {
        deleteUser(uid);
      }
    } else {
      deleteSocialInspiration(feed.id, updationList);
    }

    onClose();
  };

  handleOnAfterOpenModal = () => {
    if(this.cancelButton.current){
      this.cancelButton.current.focus();
    }
  }

  render() {
    const { showModal, title, subContent, fname, lname, t } = this.props;
    return(
      <StyledModalV2
        show={showModal ? true : false}
        onHide={this.props.onClose}
        onEntering={()=>{this.handleOnAfterOpenModal()}}
        dialogClassName={'delete-popup'}
        backdropClassName='drop-back'
      >
        <Modal.Header Hide={'closeButton'}>
          <StyledTitleV2>{t(title)}</StyledTitleV2>
        </Modal.Header>
        <StyledBodyV2>
          <BoldV2>
            {t(`Are you sure you want to delete `)} 
            <span className='name-in-popup'>{`${t(fname)} ${t(lname)}?`}</span>
            <div className="subContent">
              {t(subContent)}
            </div>
          </BoldV2>
          <SubmitContainerV2>
            <UserButtonV2
              radius="5px"
              bgColor="#FD7175"
              width="200px"
              height="50px"
              fontSize="18px"
              Align={1}
              float={1}
              marginLeft="0px"
              onClick={this.deletePost}
              font="Rubik"
              capitalize="capitalize"
              marginRight="15px"
            >
              {subContent?t("Remove"):t("Delete")}
            </UserButtonV2>
            <UserButtonV2
              radius="5px"
              bgColor="none"
              width="200px"
              height="50px"
              fontSize="18px"
              Align={1}
              float={1}
              marginLeft="0px"
              onClick={this.props.onClose}
              innerRef={this.cancelButton}
              outline={0}
              font="Rubik"
              capitalize="capitalize"
              textColor= "#9C9C9C"
              border= "border: 2px solid #9C9C9C"
              boxSizing= "border-box"
            >
              {t("cancel")}
            </UserButtonV2>
          </SubmitContainerV2>
        </StyledBodyV2>
      </StyledModalV2>
    );
  }
}

DeletePostPopUp.propTypes = {
  showModal: PropTypes.string,
  onClose: PropTypes.func,
  deleteSocialInspiration: PropTypes.func,
  feed: PropTypes.any,
  updationList: PropTypes.string,
  questionPopup: PropTypes.number,
  deleteId: PropTypes.number,
  deleteQuizQuestion: PropTypes.func,
  articleId: PropTypes.number,
  title: PropTypes.string.isRequired,
  uid: PropTypes.number,
  deleteUser: PropTypes.func,
  subContent: PropTypes.string,
  eventId: PropTypes.number,
  RemoveAttendees: PropTypes.func,
  fname:PropTypes.string,
  lname:PropTypes.string,
  t: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  deleteSocialInspiration: (id, updationList) => dispatch(deleteSocialInspiration(id, updationList)),
  RemoveAttendees: (data) => dispatch(UpdateAttendeesList(data))
});

export default connect(null, mapDispatchToProps)(withTranslation()(DeletePostPopUp));